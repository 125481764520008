import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CAMPAIGN_YEAR } from 'basics/constants/common.constant';
import { mayaCustomerInitiativeListRoute } from 'basics/constants/routes.constants';
import { useCreateCustomerInitiativeMutation } from 'basics/graphql/mutations/createCustomerInitiative';
import useGetStrategicImperativeByIdQuery from 'basics/graphql/queries/getStrategicImperativeById';
import { succesToast, warningToast } from 'basics/utils/toast';
import { CiFormSubmitCallbackType } from 'components/CustomerInitiativeForm/CustomerInitiativeForm.types';
import { useGTM } from 'hooks/useGTM';

const useCustomerInitiativeCreate = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dataLayer = useGTM();

  const [createCustomerInitiative] = useCreateCustomerInitiativeMutation();

  const urlParams = useMemo(() => new URLSearchParams(window.location.search), []);
  const parentId = urlParams.get('parentId');

  const [loading, setLoading] = useState(true);

  const parentStrategicImperative = useGetStrategicImperativeByIdQuery(parentId).data;

  useEffect(() => {
    if (parentStrategicImperative || !parentId) {
      setLoading(false);
    }
  }, [parentId, parentStrategicImperative, setLoading]);

  const handleCancelCustomerInitiativeForm = () => {
    navigate(`${mayaCustomerInitiativeListRoute}?year=${CAMPAIGN_YEAR}`);
  };

  const handleSaveCustomerInitiativeForm: CiFormSubmitCallbackType = async (ci) => {
    const { data: customerInitiativeData, error } = await createCustomerInitiative({ ...ci, isTemplate: false });
    const year = new Date(ci.dateStart).getFullYear();

    if (customerInitiativeData && !error) {
      dataLayer({
        event: 'customer_initiative',
        step: 'initiation',
      });
      succesToast(t('maya_ci_created', { title: ci.title }));
      navigate(`${mayaCustomerInitiativeListRoute}?year=${year}`);
    } else {
      warningToast(error || t('errors_standard'));
      navigate(`${mayaCustomerInitiativeListRoute}?year=${year}`);
    }
  };

  return {
    loading,
    parentStrategicImperative,
    handleSaveCustomerInitiativeForm,
    handleCancelCustomerInitiativeForm,
  };
};

export default useCustomerInitiativeCreate;
