import { useFlags } from 'launchdarkly-react-client-sdk';
import { DataLayer } from 'basics/types/gtm';

export const useGTM = () => {
  const flags = useFlags();

  const dataLayer = (obj: DataLayer) => {
    if (!flags.mayaBdcfcsd1160EnablePianoAnalytics) {
      return;
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(obj);
  };

  return dataLayer;
};
